export const environment = {
  production: true,
  apiUrl: "/api/v1/",
  // videoResourceUrl: "https://pmb-reels.s3.ap-south-1.amazonaws.com/videos",
  socketUrl: "https://www.socket.pikmybox.com/",
  p2pUrl: "https://p2p.pikmybox.com/",// p2p production
  domesticUrl: "https://domestic.pikmybox.com",
  d2Url: "https://betad2.pikmybox.com/api/v1/",
  d2CrdForAuth: {
    "clientId": "extcl2345",
    "accessCode": "accesscode12345",
    "isExternalUser": true
  }
};
